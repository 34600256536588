const abSlider = require('core/components/slider');

//this feels like not the best way to overwrite and call a super method
var superGetSliderParams = abSlider.getSliderParams;
abSlider.getSliderParams = function($slider, slideItems) {
    var params = superGetSliderParams($slider, slideItems);

    params.touch = $slider.attr('data-mouse-drag') === 'false' ? false : true;
    params.mouseDrag = $slider.attr('data-touch') === 'true' ? true : false;
    params.startIndex =  parseInt($slider.attr('data-start-index')) || 0;

    return params;
}

// Build out slide html for replacing images on variant selection
abSlider.getUpdatedSlideItems = function(images, assets, isThumbnail) {
    var slideItems = [];

    images['large'].forEach(image => {
        var $slideElement = $('<div></div>').attr('class', 'slide');
        if(image.mimetype.indexOf('video') > -1) {
            var $imageElement = $('<div class="pdp-gallery-asset slide video-container">');
            var $videoElement = $('<video />');
            var $source = $('<source />');

            $source.attr({
                src : image.url,
                type : image.mimetype
            });

            $videoElement.append($source);

            if (isThumbnail) {
                $videoElement.attr({
                    width: '75',
                    height: '75',
                    muted: true
                });
                $imageElement.append('<span class="video-thumbnail-button"></span>');
            }else{
                $videoElement.attr({
                    width: '800',
                    height: '800',
                    muted: true,
                    loop: true,
                    autoplay: true,
                    playsinline: true
                });
            }
            $imageElement.prepend($videoElement);

        }else{
            var $imageElement = $('<img/>');

            if (!isThumbnail) {
                $slideElement.attr('data-zoom-image', images['hi-res'].length ? images['hi-res'][image.index].url : images['large'][image.index].url);
            }

            $imageElement.attr({
                'src': image.url,
                'alt': image.alt + ' image number ' + (parseInt(image.index) + 1).toFixed(0),
                'class': 'd-block img-fluid',
                'itemprop': 'image'
            });
        }
        $slideElement.append($imageElement);

        var $imageLabel = $('<div class="pdp-image-label">');
        if ('imageLabel' in image && image.imageLabel.length > 0 && !isThumbnail) {
            $imageLabel.text(image.imageLabel);
            $slideElement.append($imageLabel);
        }

        slideItems.push($slideElement[0]);
    });

    abSlider.getPDPGalleryAssetSlides(slideItems, assets, isThumbnail);

    return slideItems;
}

module.exports = abSlider;