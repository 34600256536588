'use strict';

var SiteConstants = require('constants/SiteConstants');
var abSlider = require('core/components/slider');
var productTile = require('core/components/productTile');

var updateMiniCart = true;

module.exports = function () {

    $('#cartModal').on('shown.bs.modal', function (event) {
        $('body').trigger('minicart:update', {spinner:true});
    });

    $('body').on('minicart:update', function(event, data){
        var $minicart = $('.pre-nav-header').find('.minicart');
        var url = $minicart.data('action-url');
        var $cartBody = $("#cartModal .modal-body");

        if(data.spinner){
            $cartBody.spinner().start();
        }
        $.get(url, data => {
            $cartBody.html(data);
            $cartBody.find('.slider-container').each ( function() {
                abSlider.initializeSliders($(this).parent());
                productTile.init($(this).find('.product'), function (status) {
                    // Re Initialize sliders to account for TileInclude Errors
                    var tns = status.errors > 0 ? $(this).data('tns') : null;

                    if (tns) {
                        tns.destroy();
                        tns.rebuild();
                    }
                });
            })

            $.spinner().stop();
            $('body').trigger('minicart:loaded', this);
        });
    });

    $('.minicart').on('count:update', (event, count) => {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    $('body').on('minicart:loaded', function(){
        $(".minicart").addClass("has-content");
    })

    // to show the minicart
    $('body').on('minicart:show', (event, detail) => {
        var $minicart = $('.pre-nav-header').find('.minicart');
        if (window.isMobile()) {
            $minicart = $('.header-nav').find('.minicart');
            // if mobile menu is already open, close it
            if ($('body.mobile-menu-in')) {
                require('client_core/components/menu').toggleDrawer('close');
            }
        }

        var count = parseInt($minicart.find('.minicart-quantity').text(), 10);

        if (count !== 0) {

            var $miniCartModalButton = $(".minicart[data-toggle='modal']");
            var $miniCartModal = $($miniCartModalButton.data('target'));

            $miniCartModal.modal('show');

        }
    });

    // to hide the minicart
    $('body').on('minicart:hide', () => {
        $('body').removeClass('modal-open');
    });

    // any clicks outside minicart when the minicart is showing will hide minicart
    $('body').on('touchstart click', event => {
        if ($('.minicart').has(event.target).length === 0 && $('.minicart .popover.show').length > 0 && $('.modal-content').has(event.target).length === 0) {
            $('body').trigger('minicart:hide');
        }
    });

    $('body').on('click', '.minicart .hide-minicart', () => {
        $('body').trigger('minicart:hide');
    });

    $('body').on('change', '.minicart .quantity', event => {
        if ($(event.target).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });

    $('body').on('product:afterAddToCart cart:update', (e, data) => {
        updateMiniCart = true;
        if ($('.cart-page').length === 0
            && (!data.newBonusDiscountLineItem
            || Object.keys(data.newBonusDiscountLineItem).length == 0)) {

            $('body').trigger('minicart:show');
        }
    });
};
