'use strict';

const debounce = require('lodash/debounce');
const VideoHelper = require('core/components/video/videoHelper');
const brightcovePlayerLoader = require('@brightcove/player-loader').default;

const PlayerDefaults = {
    autoplay: false,
    controls: true,
    muted: false,
    loop: false,
    modal: false,
    hasThumb: false
};

/**
 * initDomElements()
 *
 * Saves generalized references to DOM elements needed by the player to operate.
 *
 * player.domElements.$player - div used to init the player API, also holds the configuration data
 * player.domElements.$thumb - thumbnail/preview image that masks the player on screen while not playing
 * player.domElements.$cntr - outer div container used to attach dimensions when needed
 */
function initDomElements() {
    let player = this;
    let $elem = $('#' + player.id);

    player.domElements = {
        $player: $elem,
        $thumb: $elem.siblings('.video-thumbnail'),
        $cntr: $elem.closest('.video-container')
    };
}

/**
 * initConfig()
 *
 * Parses and stores the configuration data attributes set by the content asset.
 * In case of parse error, fallback to this module's PlayerDefaults.
 */
function initConfig() {
    let player = this;

    let playerAttrData = {};
    try {
        playerAttrData = JSON.parse(player.domElements.$player.attr('data-player-attr'));
    } catch (ex) {
        playerAttrData = PlayerDefaults;
    }

    player.config = {
        autoplay: !!playerAttrData.autoplay,
        controls: !!playerAttrData.controls,
        muted: !!playerAttrData.mute,
        loop: !!playerAttrData.loop,
        hasThumb: playerAttrData.hasThumb,
        modal: !!playerAttrData.modal,
        videoId: player.domElements.$player.attr('data-video-id')
    };
}

/**
 * createApiPlayer()
 *
 * Create and store the vendor specific API Player.
 * The configurations set in the content asset can be overridden for specific use cases, like turning off autoplay when closing the modal.
 *
 * Also attaches any needed playback events to the player.
 *
 * @param {object} overrides - attributes that can override the defined player.config attributes
 * @returns {Promise} - loadedmetadata event Promise is returned so we don't make any calls too early
 */
function createApiPlayer(overrides) {
    let player = this;
    let config = player.config;

    // apply overrides attributes
    if (overrides) {
        config = $.extend({}, player.config, overrides);
    }

    return new Promise(function(resolve) {

        brightcovePlayerLoader({
            refNode: document.querySelector('#' + player.id),
            accountId: '5845982468001',
            videoId: config.videoId
          }).then(function(success){
                player.apiObj = success.ref

                player.apiObj.autoplay(config.autoplay);
                player.apiObj.controls(config.controls);
                player.apiObj.loop(config.loop);
                player.apiObj.muted(config.muted);

                bindPlayerEvents(player);

                player.apiObj.on('loadedmetadata', function() {
                    resolve();
                });
          });
    })
}

/**
 * bindPlayerEvents()
 *
 * Attach listeners to player events.
 *
 * @param {object} player - the player to listen to.
 */
function bindPlayerEvents(player) {
    player.apiObj.on('pause', () => {
        // save the current location
        player.cache.time = player.apiObj.currentTime();
    });

    player.apiObj.on('ended', () => {
        $('body').trigger('video:ended', player);
    });
}

/**
 * initPageEvents()
 *
 * Initializes any page load event logic for the player.
 */
function initPageEvents() {
    let player = this;
    let $playerContainer = player.domElements.$cntr;

    // pause all players at init, before deciding if they should play
    player.pause();

    if (player.config.hasThumb) {
        //handle thumbnail click
        player.domElements.$thumb.on('click', event => {
            event.preventDefault();

            if (player.config.modal) {
                player.launchModal();
            } else {
                let paused = player.apiObj.paused();

                if (!paused) {
                    player.pause();
                } else {
                    var playPromise = player.apiObj.play();

                    if (playPromise !== undefined) {
                        playPromise.then(() => {
                            player.domElements.$thumb.addClass('faded');
                        }).catch(error => {
                            console.error('play error:', error);
                        });
                    }
                }
            }
        });
    }

    if (player.domElements.$cntr.closest('.modal').length) {
        $(window).on('resize', debounce(() => player.removeSize(), 100));
    }

    if (VideoHelper.shouldPlay($playerContainer) && player.config.autoplay && !player.config.modal) {
        player.play();
    }

    $(window).on('scrollUpdate', () => {
        if (VideoHelper.shouldPlay($playerContainer)) {
            if (player.config.autoplay && !player.config.modal) {
                player.play();
            }
        } else {
            if (!player.apiObj.paused()) {
                player.pause();
            }
        }
    });
}


module.exports = function(playerId) {
    var player = this;
    this.id = playerId;
    this.type = 'brightcove';
    this.cache = {};

    /**********************************************************
     **  Calls to initialize the player and event listeners  **
     **********************************************************/

    initDomElements.call(player);

    initConfig.call(player);

    createApiPlayer.call(player).then(() => initPageEvents.call(player));

    /**************************************
     **  Generalized playback functions  **
     **************************************/

    this.play = () => {
        player.apiObj.play()
        if (player.domElements.$thumb.length && !player.config.modal) {
            player.domElements.$thumb.addClass('faded');
        }
    };

    this.pause = () => {
        player.apiObj.pause();
        if (player.domElements.$thumb.length) {
            player.domElements.$thumb.removeClass('faded');
        }
    }

    this.mute = () => {
        player.apiObj.getVolume().then((volume) => {
            player.cache.volume = volume;
        });

        player.apiObj.setVolume(0);
    };

    this.unmute = () => {
        let volume = 1;
        if (player.cachedVolume) {
            volume = player.cache.volume;
        }

        player.apiObj.setVolume(volume);
    };


    /*********************************************************
     **  Functions to support playing the video in a modal  **
     *********************************************************/

    this.setSize = () => {
        player.domElements.$cntr.css({
            height: player.domElements.$cntr.outerHeight(),
            width: player.domElements.$cntr.outerWidth()
        });
    };

    this.removeSize = () => {
        player.domElements.$cntr.css({
            height: 'auto',
            width: 'auto'
        });
    };

    this.setModal = (VideoModal) => {
        player.modal = VideoModal;
    };

    this.launchModal = () => {
        player.setSize();
        player.modal.launch(player);
    };


    /*****************************************************
     **  Event Handlers for the Bootstrap modal events  **
     *****************************************************/

     this.getShowEventHandler = () => {
        let $modal = player.modal.getModalObj();
        let $body = $('.modal-body', $modal).empty().removeClass();
        $body.addClass('modal-body ' + player.domElements.$cntr.attr('class'));
        player.domElements.$player.appendTo($body);

        if (player.cache.time) {
            player.apiObj.currentTime(player.cache.time);
        }

        player.play();

    };

    this.getHideEventHandler = () => {
        // save the current location
        player.cache.time = player.apiObj.currentTime();
        player.pause();

        player.domElements.$player.prependTo(player.domElements.$cntr);
    };

    return this;
}
