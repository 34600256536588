
const CrossSiteSession = () => {

  this.on = () => {

    $(document).on("click.crossSite", "a", (e) => {
      let href = $(e.currentTarget).attr("href");
      window.sessionRedirect(href, e);
    });
  }

  this.off = () => {
    $(document).off("click.crossSite");
  }

  this.domains = window.siteContext && window.siteContext.logos && window.siteContext.logos.filter(({externalSite}) => !externalSite).map((site) => site.host).filter((domain) => domain);

  window.sessionRedirect = (href, e) => {
    if (href) { //does the link have a href
      if (href.match(/^(http(s)?:)?\/\//)) { //is href an absolute url
        const url = new URL(href);
        if (url.hostname != window.location.hostname && this.domains.indexOf(url.hostname) > -1) { //is hostname not the current one and part of the domains array
          e && e.preventDefault();
          const sessionRedirect = `/s/Sites-meateater-US-Site/dw/shared_session_redirect?url=${encodeURIComponent(href)}`
          window.location = sessionRedirect;
          return true;
        }
      }
    }
    return false;
  }

  if (this.domains && this.domains.length) {
    this.on();
  }
}

function setSharedSession(gtag) {
    var clientIdSet = $.Deferred();
    var sessionIdSet = $.Deferred();
    var sessionId;
    var clientId;
    //console.log(window.siteContext.ga4SharedSession);

    gtag('get', window.siteContext.ga4TagID, 'client_id', function(client_id) {
        clientId = client_id;
        //console.log(clientId);
        clientIdSet.resolve();

    });
    gtag('get', window.siteContext.ga4TagID, 'session_id', function(session_id) {
        sessionId = session_id;
        //console.log(sessionId);
        sessionIdSet.resolve();
    });

    return $.when(sessionIdSet, clientIdSet).done(function(){
        //console.log('session and client_id are done');
        //console.log(sessionId, clientId);
        $.ajax({
            url: '/set-GA4',
            type: 'POST',
            dataType: 'json',
            data: {clientId: clientId, sessionId: sessionId},
            success: function (data) {
                if (data.error) {
                    console.log("error in session creation");
                } else {
                    console.log('success in session creation');
                }
            },
            error: function (err) {
                if (err) {
                    console.log("error:", err);
                } else {
                    console.log(err.responseJSON.errorMessage);
                }
            }
        });
    }).promise();
}

window.setSharedSession = setSharedSession;

module.exports = new CrossSiteSession();
