'use strict';
var base = require('base/components/clientSideValidation');
var Inputmask = require('inputmask').default; //https://github.com/RobinHerbots/Inputmask

base.validateClasses = () => {
    $(document).on("blur", ".validate-on-blur", (e)=>{
        $(e.target).closest(".validate-on-blur").addClass("was-validated");
    });
    $(document).on("select", ".validate-on-select", (e)=>{
        $(e.target).closest(".form-group").addClass("was-validated");
    })
}

base.initInputMasking = () => {
    $(() => {
        Inputmask().mask(document.querySelectorAll("input"));
    });
}

module.exports = base;
