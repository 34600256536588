'use strict';

var core = require('core/product/base');
var siteIntegrations = require('../integrations/siteIntegrationsUtils');
var toggleObject = siteIntegrations.getIntegrationSettings();
var abSlider = require('core/components/slider');

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').text();
    } else  if (($el).hasClass('single-variant-quick-add-to-cart')) {
        pid = $($el).data('pid');
    } else  if (($el).parents('.popover').length) {
        pid = $($el).closest('.product-detail.product-quick-add-to-cart').data('pid');
    } else {
        pid = $($el).closest('.product-detail:not(".bundle-item")').data('pid');
    }

    return pid;
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        this.chooseBonusProducts(response.newBonusDiscountLineItem);
    }
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVariant;
    var isSetItem = $productContainer.hasClass('product-set-item-detail') ? true : false;

    if (response.product.variationAttributes) {
        this.updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
        isVariant = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVariant) {
            $productContainer.parent('.bonus-product-item').data('pid', response.product.id);
            $productContainer.parent('.bonus-product-item').data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update primary images
    var primaryImages = response.product.images;
    var pdpGalleryAssets = response.product.pdpGalleryAssets;
    var $oldWishlistIcon = $productContainer.find('div.slide .wishlist-toggle-product').first().clone(true);

    this.createSlider(primaryImages, pdpGalleryAssets, $productContainer);
    // if variant is a product set item, update the sample image
    if (isSetItem) {
        $productContainer
            .find('.product-set-item-main-image')
            .attr('src', primaryImages.large[0].url)
            .attr('alt', primaryImages.large[0].alt);
    }

    // Update sticker
    $productContainer.find('.sticker-product-container').replaceWith(response.product.stickerHtml);

    //Update Badges
    if (response.product.badges && response.product.badges.length > 0) {
        $productContainer.find('.badge-product-container').removeClass('hidden-true');
        $productContainer.find('.badge-product-container').empty().html(buildBadgeList(response.product.badges));
    } else {
        $productContainer.find('.badge-product-container').addClass('hidden-true');
        $productContainer.find('.badge-product-container').empty().html('<span class="badge-product"><span class="badge-name"></span></span>');
    }

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $('.prices .price', $productContainer).length ? $('.prices .price', $productContainer) : $('.prices .price');
        $priceSelector.replaceWith(response.product.price.html);
    }

    // Update promotions
    $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);

    this.updateAvailabilityProcess(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product,
            $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product,
            $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty().html(this.getAttributesHtml(response.product.attributes));

    // Update wishlist
    if ($oldWishlistIcon && $oldWishlistIcon.length) {
        var $newWishlistIcon = $oldWishlistIcon;
        $newWishlistIcon.attr('data-wishlistpid', response.product.wishlistpid);

        //Make heart icon accurate
        var wishlist = require('core/wishlist/components/helpers.js');
        wishlist.updateWishlistLinkData($newWishlistIcon);

        var $newSliderMainImages = $productContainer.find('div.primary-images-main div.slide img');
        $newSliderMainImages.each((_i, newImage) => {
            var $newImage = $(newImage);
            $newImage.after($newWishlistIcon.clone(true));
        });
    }
}

function buildBadgeList(badges) {
    var badgeList = '';
    $.each(badges, function (i, badge) {
        badgeList += '<span class="badge-product ' + badge.class +'"><span class="badge-name">' + badge.name + '</span></span>';
    });
    return badgeList;
}

function needsAttributesSelected($productContainer) {
    var error = false;
    $(".product-detail-attributes .attribute", $productContainer).each(function(){
        if($(".swatch", this).length){
            var $selectedAttribute = $(".selected", this);
            if($selectedAttribute.length == 0){
                error = $(this).data("errorText");
                if($(".attribute-error", this).length == 0){
                    $(this).append(`<span class='attribute-error text--danger'>${error}</span>`);
                }
            }
        }else if($(".custom-select", this).length){
            const value = $(".custom-select option[selected]", this).data("attrValue");
            if(!value){
                error = $(this).data("errorText");
                if($(".attribute-error", this).length == 0){
                    $(this).append(`<span class='attribute-error text--danger'>${error}</span>`);
                }
            }
        }
    })
    return error;
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer, msgs) {
    var $attr = '.custom-select[data-attr="' + attr.id + '"]';
    var $defaultOption = $productContainer.find($attr + '.select-' + attr.id + ' option:first-child');
    $defaultOption.attr('value', attr.resetUrl).attr('disabled', true);
    var selectedValue = attr.values.find(function(value) {
        return value.selected;
    });

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('value', attrValue.url).removeAttr('disabled');
        var currentSelectedOption = $productContainer.find($attr + '.select-' + attr.id + ' option:selected').eq(0);
        if (!attrValue.selectable || !attrValue.available) {
            if (!attrValue.selectable) {
                $attrValue.attr('disabled', true);
            }

            // append a msg to option to tell user its not available with selected options
            $attrValue.html(attrValue.displayValue + " " + msgs.unavailableMsg);
        } else {
            $attrValue.html(attrValue.displayValue);
            if(selectedValue) {
                $productContainer.find($attr + '.select-' + attr.id + ' option').removeAttr('selected');
                $productContainer.find($attr + '.select-' + attr.id + ' option[data-attr-value="' + selectedValue.value +'"]').attr('selected', true);
                currentSelectedOption = $productContainer.find($attr + '.select-' + attr.id + ' option[data-attr-value="' + selectedValue.value +'"]');
                $($attr).prop('selectedIndex', $(currentSelectedOption).index());
            } else if (currentSelectedOption.text() == attrValue.displayValue) {
                $(currentSelectedOption).attr('selected', true);
                $($attr).prop('selectedIndex', $(currentSelectedOption).index());
            }
        }
    });
}



/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    var methods = this;

    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var bonusProductModal = $('#chooseBonusProductModal');
    if (bonusProductModal.length == 0) {
        var htmlString = '<!-- Modal -->'
            + '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog" style="z-index: 9999 !important;">'
            + '<span class="enter-message sr-only" ></span>'
            + '<div class="modal-dialog choose-bonus-product-dialog" '
            + 'data-total-qty="' + data.maxBonusItems + '"'
            + 'data-UUID="' + data.uuid + '"'
            + 'data-pliUUID="' + data.pliUUID + '"'
            + 'data-addToCartUrl="' + data.addToCartUrl + '"'
            + 'data-pageStart="0"'
            + 'data-pageSize="' + data.pageSize + '"'
            + 'data-moreURL="' + data.showProductsUrlRuleBased + '"'
            + 'data-bonusChoiceRuleBased="' + data.bonusChoiceRuleBased + '">'
            + '<!-- Modal content-->'
            + '<div class="modal-content">'
            + '<div class="modal-header">'
            + '    <span class="h3">' + data.labels.selectprods + '</span>'
            + '    <button type="button" class="close pull-right" data-dismiss="modal">'
            + '        <span aria-hidden="true">&times;</span>'
            + '        <span class="sr-only"> </span>'
            + '    </button>'
            + '</div>'
            + '<div class="modal-body"></div>'
            + '<div class="modal-footer"></div>'
            + '</div>'
            + '</div>'
            + '</div>';
        $('body').append(htmlString);
    }else{
        var modalDialog = bonusProductModal.find('.modal-dialog');
        modalDialog.attr('data-total-qty', data.maxBonusItems);
        modalDialog.attr('data-UUID', data.uuid);
        modalDialog.attr('data-pliUUID', data.pliUUID);
        modalDialog.attr('data-addToCartUrl', data.addToCartUrl);
        modalDialog.attr('data-pageSize', data.pageSize);
        modalDialog.attr('data-moreURL', data.showProductsUrlRuleBased);
        modalDialog.attr('data-bonusChoiceRuleBased', data.bonusChoiceRuleBased);

        bonusProductModal.find('.modal-body').empty();

    }
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
            var parsedHtml = methods.parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            abSlider.initializeSliders($('#chooseBonusProductModal .modal-body'));
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}


core.methods.needsAttributesSelected = needsAttributesSelected;
core.methods.handlePostCartAdd = handlePostCartAdd;
core.methods.handleVariantResponse = handleVariantResponse;
core.methods.processNonSwatchValues = processNonSwatchValues;
core.methods.chooseBonusProducts = chooseBonusProducts;
core.methods.getPidValue = getPidValue;

/**********
* shared across QV and PDP
*/
core.addToCart = function () {
   var scope = this;

   $(document).on('mouseenter', 'button.add-to-cart, button.add-to-cart-global', function (event) {
        var $button = $(event.target);
        var $container = $button.closest('.product-detail');
        if (!$container.length) {
            $container = $button.closest('.quick-view-dialog');
        }
        var $qtyStepperInput = $container.find('.quantity-stepper input');

        if ($qtyStepperInput.length && $qtyStepperInput.is(':focus')) {
            $qtyStepperInput.blur();
        }
    });

   $(document).on('click', 'button.add-to-cart:not(".status-invalid"), button.add-to-cart-global', function () {
        var self = this;
        var addToCartUrl;
        var pid;
        var pidsObj;
        var setPids;
        var quantity;

        $('body').trigger('product:beforeAddToCart', this);

        if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
            setPids = [];

            var $products = $(this).closest('.product-detail').find('.product-set-item-detail');
            if (!$products.length) {
                if ($(this).closest('.quick-view-dialog').length) {
                    $products = $(this).closest('.quick-view-dialog').find('.product-set-item-detail');
                } else {
                    $products = $('.product-detail');  // pagedesigner component 'Add all to cart btn' won't have .product-set-item-detail classes
                }
            }

            $products.each(function () {
                if (!$(this).hasClass('product-set-detail')) {
                    setPids.push({
                        pid: $(this).find('.product-id').text(),
                        qty: $(this).find('.quantity-select').val(),
                        options: scope.methods.getOptions($(this))
                    });
                }
            });
            pidsObj = JSON.stringify(setPids);
        }



        pid = scope.methods.getPidValue($(this));
        quantity = $(this).hasClass('single-variant-quick-add-to-cart') ? 1 : scope.methods.getQuantitySelected($(this));

        var $productContainer = $(this).closest('.product-detail');
        if (!$productContainer.length) {
            if ($(this).hasClass('single-variant-quick-add-to-cart')) {
                addToCartUrl = $(this).data('url');
            } else {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
                var $productModalbody = $(this).closest('.modal-content');
                addToCartUrl = scope.methods.getAddToCartUrl($productModalbody);
            }
        } else {
            addToCartUrl = scope.methods.getAddToCartUrl($productContainer);
        }

        //get gtm data
        var $ele = $(this);
        var gtmData = $ele.data('gtmdata') || $.parseJSON($ele.attr('data-gtmdata'));
        var gtmGA4Data = $ele.data('gtmga4data') || $.parseJSON($ele.attr('data-gtmga4data'));
        var addToCartEvent = {
            gtmData,
            gtmGA4Data,
            qty: quantity
        }

        var addToCartError = scope.methods.needsAttributesSelected($productContainer);

        if(!addToCartError){

            var form = {
                pid: pid,
                pidsObj: pidsObj,
                childProducts: scope.methods.getChildProducts(),
                quantity: quantity
            };

            if (!$('.bundle-item').length) {
                form.options = scope.methods.getOptions($productContainer);
            }

            $(this).trigger('updateAddToCartFormData', form);
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        data.addToCartEvent = addToCartEvent;
                        scope.methods.handlePostCartAdd(data);
                        $('body').trigger('product:afterAddToCart', data);
                        $('body').trigger('product:afterAddToCartQuickview', data); //cart page quickview only
                        $.spinner().stop();
                        scope.methods.miniCartReportingUrl(data.reportingURL);
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        }else{
            $(this).addClass("animation-shake");
            $('body').trigger('product:addToCartError', addToCartError);
            window.setTimeout(function(){
                $(self).removeClass("animation-shake");
            }, 2000);
            console.error("Attributes must be selected to add to cart.");
        }
   });
};

core.selectBonusProduct = function () {
    $(document).on('click', '.select-bonus-product', function () {
        var $choiceOfBonusProduct = $(this).parents('.choice-of-bonus-product');
        var pid = $(this).data('pid');
        var maxPids = $('.choose-bonus-product-dialog').data('total-qty');
        var submittedQty = 1
        var totalQty = 0;
        $.each($('#chooseBonusProductModal .selected-bonus-products .selected-pid'), function () {
            totalQty += $(this).data('qty');
        });
        totalQty += submittedQty;
        var optionID = $choiceOfBonusProduct.find('.product-option').data('option-id');
        var valueId = $choiceOfBonusProduct.find('.options-select option:selected').data('valueId');
        if (totalQty <= maxPids) {
            var selectedBonusProductHtml = ''
            + '<div class="selected-pid" '
            + 'data-pid="' + pid + '"'
            + 'data-qty="' + submittedQty + '"'
            + 'data-optionID="' + (optionID || '') + '"'
            + 'data-option-selected-value="' + (valueId || '') + '"'
            + '>'
            + '<div class="bonus-product-name">'
            + $choiceOfBonusProduct.find('.product-name').html()
            + '</div>'
            + '<div class="remove-bonus-product"></div>'
            + '</div>'
            ;
            $('#chooseBonusProductModal .selected-bonus-products .bonus-summary-products-container').append(selectedBonusProductHtml);
            $('.pre-cart-products').html(totalQty);
            $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
            $('body').trigger('modal:loaded', $('#chooseBonusProductModal')); // update quickview modal scroll height
        } else {
            $('.selected-bonus-products .bonus-summary').addClass('alert-danger');
        }
    });
}

core.removeBonusProduct = function () {
    $(document).on('click', '.selected-pid', function () {
        $(this).remove();
        var $selected = $('#chooseBonusProductModal .selected-bonus-products .selected-pid');
        var count = 0;
        if ($selected.length) {
            $selected.each(function () {
                count += parseInt($(this).data('qty'), 10);
            });
        }
        $('.btn.add-bonus-products').removeClass('btn-primary');
        $('.pre-cart-products').html(count);
        $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
    });
}

core.updateReadyToShip = function () {
    $('body').on('product:updateAddToCart', function (e, response) {
        var $productContainer = response.$productContainer;
        // update local add to cart (for sets)

        if(!response.product.readyToOrder || !response.product.available) {
            $productContainer.find('.ready-to-ship').removeClass('active');
            $productContainer.find("span[data-narvar-feature='eddCalculator']").removeClass('active');
        } else {
            $productContainer.find('.ready-to-ship').addClass('active');
            $productContainer.find("span[data-narvar-feature='eddCalculator']").addClass('active');
        }
    });
};

core.updateUrl = function () {
    $('body').on('product:updateAddToCart', function (e, response) {
        if(location.href.indexOf("/products/") > -1 && response.product && response.product.selectedProductUrl){
            const stateObj = { foo: 'bar' };
            history.replaceState(stateObj, '', response.product.selectedProductUrl);
        }
    });
};

core.updateAddToCart = function () {
    $('body').on('product:updateAddToCart', function (e, response) {
        var $productContainer = response.$productContainer;
        // update local add to cart (for sets)

        if($productContainer.closest(".quick-view-dialog").length){
            $productContainer = $productContainer.closest(".quick-view-dialog");
        }

        var attributesCount = response.product.variationAttributes.length;
        var attributesSelected = 0;
        response.product.variationAttributes.forEach(function(attribute){
            var selected = attribute.values.filter(function(value){
                return value.selected;
            });
            if(selected.length) attributesSelected++;
        });

        if(response.product.productType == "master" && attributesCount == attributesSelected){
            var $button = $productContainer.find('.addtocartbutton .add-to-cart');
            var $stickyButton = $productContainer.find('.addtocart-sticky-bar .addtocartbutton .add-to-cart');
            $button.text($button.data("textInvalidOptions"));
            $button.attr("disabled", "disabled");
            $button.removeClass("status-available status-soldout status-incomplete").addClass("status-invalid");
            if ($stickyButton) {
                $stickyButton.removeAttr("disabled");
            }
        } else if(!response.product.readyToOrder) {
            var $button = $productContainer.find('.addtocartbutton .add-to-cart');
            $button.text($button.data("textSelectOptions"))
            $button.removeAttr("disabled");
            $button.removeClass("status-available status-soldout status-invalid").addClass("status-incomplete");
        } else if(!response.product.available) {
            var $button = $productContainer.find('.addtocartbutton .add-to-cart');
            $button.text($button.data("textSoldOut"))
            $button.attr("disabled", "disabled");
            $button.removeClass("status-available status-invalid status-incomplete").addClass("status-soldout");
        } else {
            var $button = $productContainer.find('.addtocartbutton .add-to-cart');
            $button.text($button.data("textAddToCart"));
            $button.removeAttr("disabled");
            $button.removeClass("status-invalid status-soldout status-incomplete").addClass("status-available");
        }
    });
};

core.quickAddToCart = function () {
   $('body').on('product:singleSelectQuickAddToCart', function (e, response) {
       var addToCartUrl = getAddToCartUrl(response.container);

       var form = {
           pid: response.data.product.id,
           quantity: 1
       };

       $(response.container).trigger('updateAddToCartFormData', form);
       if (addToCartUrl) {
           $.ajax({
               url: addToCartUrl,
               method: 'POST',
               data: form,
               success: function (data) {
                   handlePostCartAdd(data);
                   $('body').trigger('product:afterAddToCart', data);
                   $.spinner().stop();
                   miniCartReportingUrl(data.reportingURL);
               },
               error: function () {
                   $.spinner().stop();
               }
           });
       }
   });
};

core.sizeChart = function() {
    $('body').on('click', '.size-chart .size-chart-launcher', event => {
        event.preventDefault();
        var url = $(event.target).attr('href');
        var productId = $(event.target).closest('.product-detail').data('pid');
        var $sizeChartModal = $('.modal[data-product=' + productId + ']').eq(0);
        var $modalBody = $sizeChartModal.find('.modal-body');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'html',
            success: function (data) {
                $modalBody.html(data);
            }
        });

        //if the sizechart is from a quickview append after all the modal-backdrops
        if ($(event.target).parents('.product-quickview').length) {
            var $sizeChartContainer = $(event.target).closest('.size-chart');

            $sizeChartModal.appendTo('body');
            $sizeChartModal.on('hide.bs.modal', event => {
                $sizeChartModal.appendTo($sizeChartContainer);
            });
        }
        $sizeChartModal.modal('show');
    });

    $('body').on('click', '#sizeChartModal .close', event =>  {
        $(event.target).closest('#sizeChartModal').modal('hide');
    });
}

core.updateHideShowOutOfStockInStockNotificationForm = function () {
    if (toggleObject.viewOutOfStockItems && toggleObject.viewBackInStockNotificationForm) {
        $('body').on('product:afterAttributeSelect', function(e, attrData) {
            var $qtyCartContainer = attrData.container && attrData.container.find('.qty-cart-container');
            var $stickyAddToCartContainer = attrData.container && attrData.container.find('.addtocart-sticky-bar');

            if ($qtyCartContainer) {
                $qtyCartContainer.each(function () {
                    var $container = $(this);
                    var $addToCartBtn = $container.find('.addtocartbutton');
                    var $qty = $container.find('.quantity');
                    var $bisnForm = $container.find('.bisnform');
                    var product = (attrData.data || {}).product || {};

                    $bisnForm.find('.submit-success').removeClass('submit-success');
                    $bisnForm.find(':input[name="pid"]').val(product.id || '');

                    if (!!product.showBISN && product.productType != "master") {
                        $addToCartBtn.addClass('d-none');
                        $qty.addClass('d-none');
                        $bisnForm.removeClass('d-none');
                    } else {
                        $addToCartBtn.removeClass('d-none');
                        if (!attrData.data.product.hideQty) {
                            $qty.removeClass('d-none');
                        }
                        $bisnForm.addClass('d-none');
                    }
                });
            }
            if ($stickyAddToCartContainer) {
                $stickyAddToCartContainer.each(function () {
                    var $container = $(this);
                    var $addToCartBtn = $container.find('.addtocartbutton');
                    var $bisnForm = $container.find('.bisnform');
                    var product = (attrData.data || {}).product || {};

                    $bisnForm.find('.submit-success').removeClass('submit-success');
                    $bisnForm.find(':input[name="pid"]').val(product.id || '');

                    if (!!product.showBISN && product.productType != "master") {
                        $addToCartBtn.addClass('d-none');
                        $bisnForm.removeClass('d-none');
                    } else {
                        $addToCartBtn.removeClass('d-none');
                        $bisnForm.addClass('d-none');
                    }
                });
            }
        });
    }
}

core.clearAttributeErrors = function () {
    $('body').on('product:afterAttributeSelect', function (e, attrData) {
        $(".attribute-error", attrData.container).remove();
    });
}

core.addBonusProductsToCart = function () {
    $(document).on('click', '.add-bonus-products', function () {
        var $readyToOrderBonusProducts = $('.choose-bonus-product-dialog .selected-pid');
        var queryString = '?pids=';
        var url = $('.choose-bonus-product-dialog').data('addtocarturl');
        var pidsObject = {
            bonusProducts: []
        };

        $.each($readyToOrderBonusProducts, function () {
            var qtyOption =
                parseInt($(this)
                    .data('qty'), 10);

            var option = null;
            if (qtyOption > 0) {
                if ($(this).data('optionid') && $(this).data('option-selected-value')) {
                    option = {};
                    option.optionId = $(this).data('optionid');
                    option.productId = $(this).data('pid');
                    option.selectedValueId = $(this).data('option-selected-value');
                }
                pidsObject.bonusProducts.push({
                    pid: $(this).data('pid'),
                    qty: qtyOption,
                    options: [option]
                });
                pidsObject.totalQty = parseInt($('.pre-cart-products').html(), 10);
            }
        });
        queryString += JSON.stringify(pidsObject);
        queryString = queryString + '&uuid=' + $('.choose-bonus-product-dialog').data('uuid');
        queryString = queryString + '&pliuuid=' + $('.choose-bonus-product-dialog').data('pliuuid');
        $.spinner().start();
        $('#chooseBonusProductModal').modal('hide');
        $.ajax({
            url: url + queryString,
            method: 'POST',
            success: function (data) {
                $.spinner().stop();
                if (data.error) {
                    $('body').trigger('product:afterAddToCart', data);
                    if ($('.add-to-cart-messages').length === 0) {
                        $('body').append('<div class="add-to-cart-messages"></div>');
                    }
                    $('.add-to-cart-messages').append(
                        '<div class="alert alert-danger add-to-basket-alert text-center"'
                        + ' role="alert">'
                        + data.errorMessage + '</div>'
                    );
                    setTimeout(function () {
                        $('.add-to-basket-alert').remove();
                    }, 3000);
                } else {
                    $('.configure-bonus-product-attributes').html(data);
                    $('.bonus-products-step2').removeClass('hidden-xl-down');
                    $('body').trigger('product:afterAddToCart', data);
                    if ($('.cart-page').length) {
                        window.location.reload();
                    } else {
                        $('body').trigger('minicart:update', {spinner:false});
                        $('.minicart-quantity').empty().append(data.totalQty);
                        $('.minicart-link').attr({
                            'aria-label': data.totalQty,
                            title: data.totalQty
                        });
                    }
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
}

core.notifyWhenBackInStock = function () {
    $('body').on('submit', '.pdp-bisn-form', function (e) {
        e.preventDefault();
        e.stopPropagation();
        //
        // Submit the Subscribe Form
        //
        var form = this,
            $form = $(form);

        $form.data('$xhr', $.ajax({
            beforeSend: function () {
                var $xhr = this.data('$xhr');

                $xhr && $xhr.abort();

                $form.removeClass('submit-error');
                $form.addClass('submit-loading');

                return form.checkValidity();
            },
            context: $form,
            data: $form.serialize(),
            method: $form.attr('method'),
            error: function() {
                // Validate each input
                this.find(':input').each(function (input) { input.checkValidity(); });

                console.error(this, arguments);
            },
            success: function (data) {
                if (data.success) {
                    $form.removeClass('submit-loading').addClass('submit-success');

                    $('body').trigger('subscribe:success', data);

                    form.reset();
                } else if (data.error) {
                    $form.removeClass('submit-loading').addClass('submit-error');
                    // Hide error after delay
                    setTimeout(function () { $form.removeClass('submit-error'); }, 5000);
                }
            },
            url: $form.attr('action')
        }));
    });
}

module.exports = core;
