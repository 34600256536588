module.exports = function(){
    $(document).ready(function(){

        var url = new URL($(".page").data("session-attributes-url"), location.origin);
        var currentParams = new URLSearchParams(location.search);

        var sid = getCookie("sid");
        url.searchParams.set("sid", sid);

        if(currentParams.get("avad")){
            url.searchParams.set("avad", currentParams.get("avad"));
        }

        if(currentParams.get("utm_source")){
            url.searchParams.set("utm_source", currentParams.get("utm_source"));
        }

        if(currentParams.get("utm_medium")){
            url.searchParams.set("utm_medium", currentParams.get("utm_medium"));
        }

        if(currentParams.get("utm_campaign")){
            url.searchParams.set("utm_campaign", currentParams.get("utm_campaign"));
        }

        if(document.referrer){
            url.searchParams.set("sessionReferrer", document.referrer);
        }

        console.log(url.toString());

        $.ajax({
            url: url.toString(),
            method: 'GET',
            success: function(data){
                window.sessionAttributes = {
                    avantLinkId: data.avantLinkId,
                    initalBrandId: data.initalBrandId,
                    sessionReferrer: data.sessionReferrer,
                    utm_source: data.utm_source,
                    utm_medium: data.utm_medium,
                    utm_campaign: data.utm_campaign
                }
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                'event': 'window_sessionAttributes'
                });
                console.log("sessionAttributes", sessionAttributes);
            },
            error: function (e) {
                console.log("Error setting session attribution", e)
            }
        });

    });
}

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
        }
    }
    return "";
}
