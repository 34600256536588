var main = require('integrations/main');
/* RVW & SFRA Base Files */

/* Client Custom */
main.baseFiles.countrySelector= require('./components/countrySelector');
main.baseFiles.header = require('./components/header');
main.baseFiles.menu = require('./components/menu');
main.baseFiles.miniCart= require('./components/miniCart');
main.baseFiles.cart= require('./cart');
main.baseFiles.search = require('./components/search');
main.baseFiles.slider = require('./components/slider').init;
main.baseFiles.tempSlider = require('./components/tempSlider');
main.baseFiles.base = require('./product/base');
main.baseFiles.quickView = require('./product/quickView');
main.baseFiles.smoothScroll = require('./components/smoothScroll');
main.baseFiles.tabAnchors = require('./components/tabAnchors');
main.baseFiles.promoCode = require('./components/promoCode');
main.baseFiles.clientSideValidation = require('./components/clientSideValidation');
main.baseFiles.video = require('./components/video');
main.baseFiles.sessionAttribution = require('./components/sessionAttribution');
main.baseFiles.imageComparison = require('./components/imageComparison');

window.crossSiteSession = require('./components/crossSiteSession');

module.exports = main;
