module.exports = {
    init: function(){

        $('.promo-code-form').submit(function (e) {
            e.preventDefault();

            var currentItemCount = parseInt($('.minicart-quantity')[0].innerText) || 0;

            var $form = $(this);

            if (!$('input[name="couponCode"]', $form).val()) {
                $form.addClass("submit-error");
                return false;
            }

            $('body').trigger('promotion:beforeUpdate');

            $form.removeClass("submit-error").removeClass("submit-success").addClass("submit-loading");

            console.log("form", $form);

            $.ajax({
                url: $form.attr('action'),
                type: 'GET',
                dataType: 'json',
                data: $form.serialize(),
                success: function (data) {
                    $form.removeClass("submit-loading")
                    if (data.error) {
                        $(".form-error small", $form).empty().append(data.errorMessage);
                        $form.addClass("submit-error");
                    } else {
                        var successAction = $form.data("successAction");
                        if(successAction == "redirect"){
                            var successUrl = $form.data("successUrl");
                            if(!window.sessionRedirect || !window.sessionRedirect(successUrl)){
                                window.location = successUrl;
                            }
                        }

                        // If cart item was added/removed via promo code submit
                        if (['', null, undefined].indexOf(data.numItems) == -1 && currentItemCount !== data.numItems) {

                            // Clean Url Structure of any erroneous parameters
                            if (window.history && ['', null, undefined].indexOf(data.actionUrls.showUrl) === -1) {
                                history.replaceState({}, null, data.actionUrls.showUrl);
                            }
                            //console.log(data);
                            //alert("redirect");
                            // Force uncached reload
                            window.location.reload(true);
                        } else {
                            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                            $('input[name="couponCode"]', $form).val('');
                            $('body').trigger('promotion:success', data);
                        }
                    }
                },
                error: function (err) {
                    $('body').trigger('promotion:error', err);
                    if (err.responseJSON.redirectUrl) {
                        //console.log(err);
                        //alert("err redirect");
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        $form.removeClass("submit-loading").addClass("submit-error");
                    }
                }
            });
            return false;
        });

        $('body').on('click', '.js-remove-coupon', function (e) {
            e.preventDefault();

            var currentItemCount = parseInt($('.minicart-quantity')[0].innerText) || 0;

            var url = new URL($(this).data('action'), window.location.origin);
            var uuid = $(this).data('uuid');
            var couponCode = $(this).data('code');
            url.searchParams.set("code", couponCode);
            url.searchParams.set("uuid", uuid);

            $('body').trigger('promotion:beforeUpdate');
            $('.coupon-uuid-' + uuid).hide();

            $.ajax({
                url: url.toString(),
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    $('.coupon-uuid-' + uuid).remove();
                    $('body').trigger('promotion:success', data);
                },
                error: function (err) {
                    $('body').trigger('promotion:error', err);
                    if (err.responseJSON && err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        $('.coupon-uuid-' + uuid).show();
                        $('.coupons-and-promos .remove-coupon-error').addClass("d-block");
                    }
                }
            });

        });
    }
}