'use strict';

// This extends the default bootstrap tabs functionality across the site to allow
// selected tabs to remain selected through page refreshes, and secondary tabs to
// automaticly activate based on location.hash

module.exports = {
    addAnchorPopstate: () => {
        $('a[data-toggle="tab"]').on('click', event => {
            const tabId = $(event.target).attr('id');
            const state = { 'tab': tabId }
            history.pushState(state, '', '#' + tabId);
        });
    },
    scrollToSection: () => {
        if(location.hash.indexOf("#") == 0){
            var $anchor = $(location.hash + '[data-toggle="tab"]');
            if ($anchor.length) {
                $anchor.tab('show')
                const headerOffset = window.isMobile ? 250: 150;
                $('html,body').animate({scrollTop:$anchor.offset().top - headerOffset}, 500);
            }
        }
    }

};
