'use strict';

var core = require('core/components/menu');
var SiteConstants = require('constants/SiteConstants');
var headerUtils = require('core/utilities/headerUtils');

function toggleDrawer(status) {
    if(!status) return;

    var $header = $('header');
    var $mainMenu = $header.find('.main-menu');
    var $navbar = $mainMenu.find('.navbar-nav');
    var $modalBackground = $('.modal-background');

    var $headerBanner = $header.find('.header-banner');
    var $headerNav = $header.find('.header-nav');
    var $preNavHeader = $header.find('.pre-nav-header');

    if (status === 'open') {
        $('body').addClass('mobile-menu-in');
        setTimeout(() => {

            var headerNavHeight = headerUtils.getHeaderHeight();
            const style = `top: ${headerNavHeight}px; height:calc(100vh - ${headerNavHeight + 100}px); height:calc(100dvh - ${headerNavHeight}px)`
            $mainMenu
                .addClass('in')
                .attr('aria-hidden', 'false')
                .attr('style', style)
                .siblings().attr('aria-hidden', 'true');
            $modalBackground
                .fadeIn(SiteConstants.TransitionSpeed)
                .css('top', headerNavHeight);
            $header
                .siblings().attr('aria-hidden', 'true');
            $navbar
                .find('.nav-link').first().focus();
        }, SiteConstants.TransitionSpeed);

    } else {
        $('body').removeClass('mobile-menu-in');
        $navbar
            .find('.nav-menu, .top-category').detach();
        $navbar
            .find('.thirdCatOpen').removeClass('thirdCatOpen');
        $navbar
            .find('.show').removeClass('show');
        $mainMenu
            .removeClass('in')
            .attr('aria-hidden', 'true')
            .siblings().attr('aria-hidden', 'false');
        $header
            .siblings().attr('aria-hidden', 'false');
        $modalBackground
            .fadeOut(SiteConstants.TransitionSpeed);

        setTimeout(() => {
            $modalBackground.css('top', '');
            $headerNav.addClass('transparent-nav');
        }, SiteConstants.TransitionSpeed);
    }
};

function navbarToggler() {
    $('.navbar-toggler').click(event => {
        event.preventDefault();
        if ($('body').hasClass('mobile-menu-in')) {
            toggleDrawer('close');
            $('.header-nav').addClass('transparent-nav');
        } else {
            toggleDrawer('open');
            $('.header-nav').removeClass('transparent-nav');
        }
    });

    $('html').on('click', '.mobile-menu-in .modal-background', () => toggleDrawer('close'));
};

function dropdownMenu() {
    // Custom dropdown behaviors for top menu
    var $dropdownMenu = $('.nav-item.custom-dropdown:not(.disabled)');
    var $dropdownMenuLink = $('.nav-item.custom-dropdown:not(.disabled) [data-toggle="dropdown"]');
    $dropdownMenuLink.on('click', event => {
        event.stopPropagation(); // Prevent Bootstrap dropdown click events
        if (window.isMobile()) {
            event.preventDefault();
            // Default drawer behavior on mobile
            var $dropdownLink = $(event.target);
            var parentCategoryName = event.target.innerText ? event.target.innerText : 'Back';
            var $dropdown = $dropdownLink.closest('.custom-dropdown');
            var $li = $('<li class="dropdown-item top-category" role="button"></li>');
            var $closeMenu = $('<li class="nav-menu"></li>');
            var link = $dropdownLink.clone().removeClass('dropdown-toggle')
                .removeAttr('data-toggle')
                .removeAttr('aria-expanded')
                .attr('aria-haspopup', 'false');
            $li.append(link);
            $closeMenu.append($('.close-menu').first().clone());
            var $backButton = $closeMenu.find('button.back').first();
            var $buttonChildren = $backButton.children();
            $backButton.text(parentCategoryName).append($buttonChildren);
            $dropdown.children('.dropdown-menu')
                .prepend($closeMenu)
                .attr('aria-hidden', 'false')
                .find('a').removeAttr('tabindex', '-1');
            $dropdown.addClass('show');
            $dropdownLink.attr('aria-expanded', 'true');
            $dropdown.closest('.menu-group').find('.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
            $(link).focus();
            $(".main-menu").scrollTop(0);
        } else {
            // Restore link behavior on desktop
            link = $(event.target).attr('href');
            if (link) {
                location.href = $(event.target).attr('href');
            }
        }
    });

    // Desktop - open menu using hoverIntent to prevent unintentional opening
    $dropdownMenu.hoverIntent({
        over: function(){
            if (!window.isMobile()) {
                var $eventElement = $(this);
                // Close all dropdowns
                $('.navbar-nav > li').each((index, element) => {
                    var $navItem = $(element);
                    if ($navItem.hasClass('show')) {
                        $navItem.removeClass('show');
                        $navItem.children('ul.dropdown-menu').removeClass('show');
                        $navItem.children('.nav-link').attr('aria-expanded', 'false');
                    }
                });
                // Open current dropdown
                $eventElement.addClass('show');
                $eventElement.find('[data-toggle="dropdown"]').siblings('.dropdown-menu').addClass('show').attr('aria-hidden', 'false');
                $eventElement.find('[data-toggle="dropdown"]').attr('aria-expanded', 'true');
            }
        },
        out: function(){
            if (!window.isMobile()) {
                var $dropdown = $(this);
                // Close current dropdown
                $dropdown.removeClass('show');
                $dropdown.children('.dropdown-menu').removeClass('show').attr('aria-hidden', 'true');
                $dropdown.children('.nav-link').attr('aria-expanded', 'false');
            }
        },
        timeout: 100, //miliseconds to delay out
        interval: 10 //interval for determining hover intent
    });

};

core.dropdownMenu = dropdownMenu;
core.toggleDrawer = toggleDrawer;
core.navbarToggler = navbarToggler;

module.exports = core;
