'use strict';

let scrollingDirection = "down";

const updateHeader = (entries) => {
    entries.forEach(entry => {
        if(entry.isIntersecting){
            $('.header-nav').removeClass('fixed');
            $('header').removeClass('scrolled');
        }else{
            setTimeout(function(){
                $('.header-nav').addClass('fixed');
            }, 500);
            $('header').addClass('scrolled');
        }
    })
}

const shouldCloseDropdowns = (entries) => {
    entries.forEach(entry => {
        if(entry.target.classList.contains('scroll-direction-down') && scrollingDirection == "up"){
            $(entry.target).find('.dropdown-menu.show').dropdown('toggle');
            scrollingDirection = "down";
        }else if(entry.target.classList.contains('scroll-direction-up') && scrollingDirection == "down"){
            scrollingDirection = "up";
        }
    });
}

module.exports = function() {

    // Fix/unfix header to top of screen, dependent on class added by custom preference
    // Note: This is intentionally not using utilities/scroll.js so that it can fix immediately vs. being debounced. Should be replaced with CSS position:sticky once IE11 support is dropped.
    var hasFixedHeader = $('header').hasClass('fixed-header');
    var hasTransparentHeader = $('header').hasClass('transparent-header');

    // adding padding to top
    if(hasFixedHeader && !hasTransparentHeader){
        var headerHeight = $('header').outerHeight();
        if(headerHeight > 0){
            $("#maincontent").css("padding-top", headerHeight + "px");
        }
    }

    //observe top of page and update header
    const topOfPage = document.querySelector('#topOfPage');
    if(topOfPage){
        const headerObserver = new IntersectionObserver(updateHeader);
        headerObserver.observe(topOfPage);
    }

    //observe scroll direction and update header dropdowns
    const html = document.querySelector('html');
    if(html){
        const htmlObserver = new MutationObserver(shouldCloseDropdowns);
        htmlObserver.observe(html, { attributes: true, childList: false, subtree: false });
    }


    var isMobile = window.isMobile();
    if ($('header').hasClass('transparent-header') && !isMobile) {
        $(($('.logo-left').length ? '.header-nav' : '.header-nav:not(.secondary-nav)')).hover(
                function() {
                    $(this).removeClass('transparent-nav').find('.secondary-nav').removeClass('transparent-nav');
                    $(this)[$(this).hasClass('secondary-nav') ? 'prev' : 'next']('.header-nav').removeClass('transparent-nav');
                }, function() {
                    $(this).addClass('transparent-nav').find('.secondary-nav').addClass('transparent-nav');
                    $(this)[$(this).hasClass('secondary-nav') ? 'prev' : 'next']('.header-nav').addClass('transparent-nav');
                }
        );
    }

    // Check for presence of content assets in MegaMenu
    var $megamenuDropdown = $('.megamenu > .dropdown-menu');

    $megamenuDropdown.each((index, element) => {
        var $megamenu = $(element);
        if ($megamenu.find('.megamenu-asset-1').children().length) {
            $megamenu.addClass('has-asset-1');
        }
        if ($megamenu.find('.megamenu-asset-2').children().length) {
            $megamenu.addClass('has-asset-2');
        }
    });

    // Show/hide content assets on mobile based on category level
    if (isMobile) {
        var $thirdMenuBackBtn = $('.megamenu .menu-subcategories > .dropdown-item'),
            $thirdSubLink = $('.megamenu .menu-subcategories > .dropdown-item > .dropdown-link');

        $thirdSubLink.on('click', function () {
            $('.megamenu.show .menu-subcategories').parent('li').addClass('thirdCatOpen');
        });
        $thirdMenuBackBtn.on('click', function () {
            $('.megamenu.show .menu-subcategories').parent('li').removeClass('thirdCatOpen');
        });
    }

    $('.main-menu .navbar-nav').on('mouseenter mouseleave', function () {
        $(this).toggleClass('nav-item-hover');
    });

    $('.main-menu .navbar-nav:not(.secondary-navbar) .nav-item').on('mouseenter mouseleave', function () {
        if ($('#top-header .header-nav.secondary-nav').length && !this.classList.value.includes('inview')) {
            $('#top-header  .header-nav.secondary-nav').toggleClass('show hide-secondary-nav');
        }
    });

    $('header .site-search').on('mouseenter', event => {
        var $siteSearch = $(event.target).closest('.site-search');
        $siteSearch.addClass('expanded');
    });

    $('.main-menu .navbar-nav:not(.secondary-navbar) .nav-item').on('mouseenter mouseleave', function () {
        if ($('#top-header .header-nav.secondary-nav').length && !this.classList.value.includes('inview')) {
            $('#top-header  .header-nav.secondary-nav').toggleClass('show hide-secondary-nav');
        }
    });

    $('header .site-search').on('mouseleave', event => {
        var $siteSearch = $(event.target).closest('.site-search');
        var $input = $siteSearch.find('input');
        // Only collapse if the user isn't typing and hasn't entered text
        if (!$input.is(':focus') && !$input.val()) {
            $siteSearch.removeClass('expanded');
        }
    });

    $('form[name=simpleSearch]').on('submit', event => {
        var $siteSearch = $(event.target).closest('.site-search');
        var $input = $siteSearch.find('input[name=q]');
        //keep search from submitting if query is empty
        if ($input.val() == "") {
            $input.focus();
            event.preventDefault();
        }
    });

    $('#headerSearchModal').on('shown.bs.modal', () => {
        $('.search-field').trigger('focus');
    });

};
