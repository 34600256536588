'use strict';

var keyboardAccessibility = require('base/components/keyboardAccessibility');

module.exports = function () {

    var setLocaleRequest = (localeCode, localeCurrencyCode) => {
        var action = $('.page').data('action');
        var queryString = $('.page').data('querystring');
        var url = $('.country-selector').data('url');

        var searchParams = new URLSearchParams("");
        if(queryString){
            searchParams.set("queryString", queryString);
        }
        if(location.hash){
            searchParams.set("hash", location.hash);
        }
        localeCode && searchParams.set("code", localeCode);
        localeCurrencyCode && searchParams.set("CurrencyCode", localeCurrencyCode);
        action && searchParams.set("action", action);

        console.log(action, searchParams.toString());

        if(action && localeCode){
            window.location = url + "?" + searchParams.toString();
        }
    }

    var setUrlLang = (href, lang) => {
        if(href.startsWith("#")){ // don't add local to hash hrefs's
            return href;
        }
        let urlSubParts, urlBase, urlSearch, urlHash;
        const urlParts = href.split("?");
        if(urlParts.length == 1){
            urlSubParts = urlParts[0].split("#")
            urlBase = urlSubParts[0];
            urlSearch = "";
            urlHash = urlSubParts[1];
        }else{
            urlBase = urlParts[0];
            urlSubParts = urlParts[1].split("#");
            urlSearch = urlSubParts[0];
            urlHash = urlSubParts[1];
        }
        const urlParams = new URLSearchParams(urlSearch);
        urlParams.set("lang", lang);
        return urlBase + "?" + urlParams.toString() + (urlHash ? "#" + urlHash : "");
    }

    var currentLocale = $(".country-selector").data("currentLocale");
    var sessionLocale = $(".country-selector").data("sessionLocale");

    if(currentLocale != sessionLocale){
        setLocaleRequest(sessionLocale);
    }

    $(document).on("click.locale", "a:not([role='tab'])", (e) => {
        let href = $(e.currentTarget).attr("href");
        if (href) { //does the link have a href
            href = setUrlLang(href, currentLocale);
            $(e.currentTarget).attr("href", href);
        }
    })

    $('.country-selector a').click(function (e) {
        e.preventDefault();

        var localeCode = $(this).data('locale');
        var localeCurrencyCode = $(this).data('currencycode');

        setLocaleRequest(localeCode, localeCurrencyCode);

    });

    keyboardAccessibility('.navbar-header .country-selector',
        {
            40: function ($countryOptions) { // down
                if ($(this).is(':focus')) {
                    $countryOptions.first().focus();
                } else {
                    $(':focus').next().focus();
                }
            },
            38: function ($countryOptions) { // up
                if ($countryOptions.first().is(':focus') || $(this).is(':focus')) {
                    $(this).focus();
                    $(this).removeClass('show');
                } else {
                    $(':focus').prev().focus();
                }
            },
            27: function () { // escape
                $(this).focus();
                $(this).removeClass('show').children('.dropdown-menu').removeClass('show');
            },
            9: function () { // tab
                $(this).removeClass('show').children('.dropdown-menu').removeClass('show');
            }
        },
        function () {
            if (!($(this).hasClass('show'))) {
                $(this).addClass('show');
            }
            return $(this).find('.dropdown-country-selector').children('a');
        }
    );

    $('.navbar-header .country-selector').on('focusin', function () {
        $(this).addClass('show').children('.dropdown-menu').addClass('show');
    });
};
