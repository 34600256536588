'use strict';

var core = require('core/product/quickView');
var modal = require('core/components/modal');
const { updateAddAllToCart } = require('core/product/detail');

/**
 * @typedef {Object} QuickViewHtml
 * @property {string} body - Main Quick View body
 * @property {string} footer - Quick View footer content
 */

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 */
function fillModalElement(selectedValueUrl) {
    var $modal = $('#quickViewModal');
    $modal.find('.modal-body').spinner().start();
    $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        dataType: 'json',
        success: function(data) {
            var parsedHtml = modal.parseHtml(data.renderedTemplate);

            $modal.find('.modal-body').empty();
            $modal.find('.modal-body').html(parsedHtml.body);

            // Append assets from PDP Gallery Asset IDs attribute
            if ('pdpGalleryAssets' in data.product) {
                var pdpGalleryAssets = data.product.pdpGalleryAssets;
                var $assetSlides = $modal.find('.primary-images-main .pdp-gallery-asset');
                let hasVideo = false;

                $assetSlides.each((index, slide) => {
                    var $slideElement = $(slide);
                    var asset = pdpGalleryAssets[index];
                    if (asset.isVideo) {
                        hasVideo = true;
                    }

                    $.ajax({
                        url: asset.assetRenderUrl,
                        method: 'GET',
                        success: assetData => {
                            var $sliderContainer = $slideElement.closest('.slider-container');
                            $slideElement.html(assetData);

                            // after all assets are added, reinit any necessary javascript
                            if (index + 1 >= pdpGalleryAssets.length) {
                                $('body').trigger('quickview:ready', $('#quickViewModal')); // sending custom event for slider init
                                $slideElement.trigger('tooltip:init'); // reinit tooltips in case of hotspot asset

                                if (hasVideo) {
                                    $('body').trigger('ajax:load.ajaxEvents', [$sliderContainer]); // reinit videos in video/index.js
                                    $('body').trigger('slider:videosLoaded'); // trigger custom event set up in bindSliderUpdateEvent
                                }
                            }
                        },
                        error: err => {
                            console.error('There was an issue retrieving this asset: ', err);
                        }
                    });
                });
            }

            $modal.find('.modal-footer').html(parsedHtml.footer);
            $modal.find('.full-pdp-link').text(data.quickViewFullDetailMsg);
            $modal.find('.full-pdp-link').attr('href', data.absoluteProductURL);
            $modal.find('.modal-header .close .sr-only').text(data.closeButtonText);
            $modal.find('.enter-message').text(data.enterDialogMessage);
            $modal.find('#quickViewModal').modal('show');

            if (data.dialogTitle) {
                $modal.find('.modal-header').prepend('<div class="modal-title">' + data.dialogTitle + '</div>')
            }

            if (data.product.productType === 'set') {
                updateAddAllToCart();
            }

            $('body').trigger('modal:loaded', $('#quickViewModal')); // sending custom event for scroll body sizing
            $('body').trigger('quickview:ready', $('#quickViewModal')); // sending custom event for slider init
            $('body').trigger('tooltip:init');

            $.spinner().stop();
        },
        error: function() {
            $.spinner().stop();
        }
    });
}

const showQuickview = function() {
    $('body').on('click', '.quickview', function (e) {
        e.preventDefault();
        var selectedValueUrl = $(this).closest('a.quickview').attr('href');
        $(e.target).trigger('quickview:show');
        modal.getModalHtmlElement('quickViewModal', 'quick-view-dialog');
        core.fillModalElement(selectedValueUrl);
    });
}

core.showQuickview = showQuickview;
core.fillModalElement = fillModalElement;

module.exports = core;
