
var gsap = require("gsap").gsap;

class TemperatureSlider extends HTMLElement {
    constructor() {
        super();

        this.fill = this.querySelector('.temperature-slider__fill');
        this.trackFill = this.querySelector('.temperature-slider__track-fill');
        this.handle = this.querySelector('.temperature-slider__handle');
        this.tns = $(this).data("tns");
        this.slides = this.querySelectorAll('.slide');
        this.dragging = false;
    }

    connectedCallback() {
        const observerOptions = {
            childList: true,
            subtree: true,
            attributes: true,
        };
        this.slides.forEach((slide, index) => {
            const observer = new MutationObserver(this.mutationCallback(index));
            observer.observe(slide, observerOptions);
            slide.classList.contains('tns-nav-active') && this.setIndex(index);
        });

        this.handle.addEventListener("mousedown", this.bindMove.bind(this));
        this.handle.addEventListener("touchstart", this.bindMove.bind(this));
    }

    unbindMove = () => {

        this.dragging = false;
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('touchmove', this.onMouseMove);
        document.removeEventListener("mouseup", this.unbindMove);

        const inertia = (this.speed * .8);
        const slideWidth = this.fillWidth / (this.slides.length - 1);

        presumedIndex = Math.round((this.offset + (inertia * slideWidth)) / slideWidth);
        if(presumedIndex < 0) presumedIndex = 0;
        if(presumedIndex > this.slides.length - 1) presumedIndex = this.slides.length - 1;
        this.slides[presumedIndex].click();
        this.setIndex(presumedIndex);
    }

    bindMove = () =>  {
        this.dragging = true;
        document.addEventListener("mouseup", this.unbindMove);
        document.addEventListener("touchend", this.unbindMove);
        this.fillWidth = this.fill.offsetWidth;
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('touchmove', this.onMouseMove);
    }

    onMouseMove = (event) => {

        if(event.type === 'touchmove') {
            event = event.touches[0];
        }

        const offset = event.clientX - this.fill.getBoundingClientRect().left;

        if(offset > 0 && offset < this.fillWidth) {

            const now = Date.now();
            this.speed = (event.clientX - this.lastX) / (now - this.lastTime);
            this.lastX = event.clientX;
            this.lastTime = now;

            this.offset = offset;
            gsap.set(this.handle, {
                x: this.offset + 'px'
            });
            gsap.set(this.trackFill, {
                width: this.offset + 'px'
            });
        }
    }

    setIndex(index) {
        this.setAttribute('data-index', index);
        this.style.setProperty('--current-slide', index);
        this.index = index;
        this.fillWidth = this.fill.offsetWidth;
        this.offset = index / ( this.slides.length - 1 ) * this.fillWidth;
        gsap.to(this.handle,  {
            x: this.offset + 'px',
            duration: 0.3,
        });
        gsap.to(this.trackFill,  {
            width: this.offset + 'px',
            duration: 0.3,
        });
    }

    mutationCallback = (index) => (mutationsList, observer) => {
        mutationsList.forEach(mutation => {
            if (mutation.type === 'attributes' && mutation.attributeName === 'class' && mutation.target.classList.contains('tns-nav-active')) {
                this.setIndex(index);
            }
        });
    }
}

module.exports = () => {
    if (!window.customElements.get("temperature-slide")) {
        customElements.define("temperature-slider", TemperatureSlider);
    }
}